<template lang="pug">
  v-container.py-0.documents
    v-img.documents__image(:src="require('@/assets/images/bg-partner-page.jpg')")
    .documents__items
      h1 Документация по автоматизированной информационной системе АИС
        |  "Автоматизированная система мониторинга и аналитики" (АИС "АСМА"):
      a.primary--text(
        v-for="(doc, idx) in documents"
        :key="idx"
        download
        :href="doc.link"
      ) {{ doc.title }}
      v-alert(
        type="info"
      )
        span.white--text
          | Стоимость использования программного обеспечения устанавливается для субъекта Российской Федерации,
          |  без ограничения числа пользователей, зарегистрированных на территории субъекта Российской Федерации.
          |  По вопросам приобретения неисключительных прав обращайтесь по адресу
          a.ml-2.white--text.text-decoration-underline(
            text href="mailto:pfdo@pfdo.ru" style="display: inline"
          ) pfdo@pfdo.ru
</template>

<script>
export default {
  name: 'TheDocuments',
  data: () => ({
    documents: [
      {
        title: 'Информация для установки и эксплуатации АИС "АСМА"',
        link: 'https://15.pfdo.ru/file/partner-documents?filename=Инструкция_по_установке_и_запуску_2.docx',
      },
      {
        title: 'Функциональное описание АИС "АСМА"',
        link: 'https://15.pfdo.ru/file/partner-documents?filename=Документация_функциональное_описание_3.docx',
      },
      {
        title: 'Документация процессов, обеспечивающих поддержание жизненного цикла АИС «АСМА» ',
        link: 'https://15.pfdo.ru/file/partner-documents?filename=Документация_Процессов_поддержания_4.docx',
      },
    ],
  }),
};
</script>

<style scoped lang="scss">

  .documents {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &__image {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
    }

    &__items {
      position: relative;
      z-index: 2;
      background-color: rgba(#fff, .9);
      padding: 40px 30px;
      width: 100%;

      h1 {
        font-size: 24px;
        margin-bottom: 30px;
      }

      a {
        display: block;
        font-size: 18px;
        margin-bottom: 10px;
      }

      @media (max-width: 960px) {
        padding: 20px 15px;

        h1 {
          font-size: 16px;
        }

        a {
          font-size: 16px;
        }
      }
    }
  }

</style>
